.NavTableResults{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  justify-content: space-between;
}
.NavTableResultsSmall{
  width: 30%;
  margin: 0 auto;
}

.NavTableResults__item{
  cursor: pointer;
  padding: 2px 2vw;
  border-bottom: 2px solid white ;
  flex-grow: 1;
  text-align: center;
}

.activeNav{
  border-bottom: 2px solid red ;
}

.legend {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  margin: 0 auto 10px;
  gap: 5px;
  width: 80%;
}
@media screen and (max-width: 600px) {
  .legend {
    flex-direction: column;
  }
}

.legend div{
  display: flex;
  gap: 3px;
}
.legend .p {
  line-height: 20px;
  font-size: 12px;
  white-space: nowrap;
  font-style: italic;
}
.legend .red {
  color: #cf002d;
  font-family:'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
  font-size: 15px;
}
.legend .blue {
  color: #438ed7;
  font-family:'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
  font-size: 15px;
}