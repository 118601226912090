.ListNews{
  width: 80%;
  margin: 10vh auto;
  text-align: center;
  height: 40vh;
}
.newsHeaderListNews{
  margin:  3vh 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.newsListNews{
  height: 90%;
}
.newsListNews .newsCard {
  margin-bottom: 3vh;
}

@media screen and (max-width: 960px) {
  .ListNews{
    width: 100%;
    margin: 0 auto;
    height: fit-content;
  }
}