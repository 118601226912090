.contactTemplate{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.contactImage{
  position: relative;
  background-image: url("../../../assets/image/Section\ Hero.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  width: 50%;
  height: 85vh;
}
.logoContact{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contactForm{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ED0033;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .contactTemplate{
    flex-direction: column;
  }
  .contactImage{
    width: 100%;
    height: 30vh;
  }
  .contactForm{
    width: 100%;
    height: 70vh;
  }
}
  