.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  white-space: nowrap;
}

.button-small {
  padding: 5px 20px;
  min-width: 87px;
  height: 26px;
}
.button-medium {
  padding: 10px 30px;
  min-width: 121px;
  height: 36px;
}
.button-large {
  padding: 10px 30px;
  min-width: 134px;
  height: 40px;
}

.button-black {
  background: #0f0f0f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0;
}
.button-danger {
  background: #ed0033;
  border: 0;
  border-radius: 5px;
}
