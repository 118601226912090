.roundDetailTemplate {
  background-color: #f5f5f5;
  height: 100%;
}

.roundDetailS3 {
  padding: 5vh 0px;
}
.roundDetailS3 .h2 {
  margin-left: 10%;
}


/* Custom details on the compoenents*/
.roundDetailTemplate .circuitSection3.backWhite {
  background-color: #00000088;
}

.tablePractice{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
}
.tablePractice div{
  margin-bottom: 25px;
  border-collapse: collapse;
}
.tablePractice table{
  border:none;
}

.roundDetailTemplate
  .circuitSection
  .circuitSection1
  .InfoCircuitRoundContainer
  .round,
.roundDetailTemplate
  .circuitSection
  .circuitSection1
  .InfoCircuitRoundContainer
  .separator {
  display: none;
}

.roundDetailTemplate .circuitSection .circuitSection1 .posteDate {
  display: none;
}

@media screen and (max-width: 800px) {
  .roundDetailS3Title{
    display: flex;
  }
}


@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);





.table-title h3 {
   color: #fafafa;
   font-size: 30px;
   font-weight: 400;
   font-style:normal;
   font-family: "Roboto", helvetica, arial, sans-serif;
   text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
   text-transform:uppercase;
}


/*** Table Styles **/

/* .table-fill {
  margin: auto;
  max-width: 1000px;
  padding:5px;
  width: 100%;
  overflow-y: hidden;
}
 
.table-fill th {
  color:#D5DDE5;;
  background:#1b1e24;
  border-bottom:4px solid #9ea7af;
  border-right: 1px solid #343a45;
  font-size:23px;
  font-weight: 100;
  padding:24px;
  text-align:left;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align:middle;
}

.table-fill tr:first-child td {
  background-color: pink;
  border-top-left-radius:3px;
}
 

.table-fill tr {
  border-top: 1px solid #C1C3D1;
  border-bottom: 1px solid #C1C3D1;
  color:#666B85;
  font-size:16px;
  font-weight:normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}
 
.table-fill tr:hover td {
  background:#4E5066;
  color:#FFFFFF;
  border-top: 1px solid #22262e;
}
 
.table-fill tr:first-child {
  border-top:none;
}

.table-fill tr:last-child {
  border-bottom:none;
}
 
.table-fill tr:nth-child(odd) td {
  background:#EBEBEB;
}
 
.table-fill tr:nth-child(odd):hover td {
  background:#4E5066;
}

.table-fill tr:last-child td:first-child {
  border-bottom-left-radius:3px;
}
 
.table-fill tr:last-child td:last-child {
  border-bottom-right-radius:3px;
}
 
.table-fill td {
  background:#FFFFFF;
  padding:4px 20px;
  text-align:left;
  vertical-align:middle;
  font-weight:300;
  font-size:18px;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  border:none;
}

.table-fill td:last-child {
  border-right: 0px;
}

.table-fill th.text-left {
  text-align: left;
}

.table-fill th.text-center {
  text-align: center;
}

.table-fill th.text-right {
  text-align: right;
}

.table-fill td.text-left {
  text-align: left;
}

.table-fill td.text-center {
  text-align: center;
} */
.table-fill{
  width: 100%;
}

.table-fill tr{
  width: auto;
  overflow: hidden;
}
.table-fill td{
  width: auto;
  height: 100%;
}


.table-fill table {
  width: 100%;
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing:  0px 10px;
  table-layout: fixed;
  
}
.table-fill table td,
.table-fill table th {
  padding: 0.5rem 1rem;
}

.table-fill table tr {
  background: #ffffff;
  padding: 4px 5px;
  text-align: center;
}
.table-fill table tr:hover{
  background: #ffd;
}


.table-fill table tbody tr:first-child{
  padding: 3px;
  position: sticky;
  text-align: center;
  /*width: 15vh;*/
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;

}
