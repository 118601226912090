.fullwidth {
  width: 100%;
}

.circuitSection {
  position: relative;
  width: 100%;
  border-bottom: solid 4px #000;
}
.circuitSectionImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: relative;
  padding-top: 3vw;
  min-height: 85vh;
}

.circuitSectionImage .uk-container {
  padding-left: 70px;
  padding-right: 70px;
}

.roundDetailTemplate .circuitSectionImage .circuitSection1 {
  justify-content: flex-start;
}

.circuitSectionImage > div {
  z-index: 2;
}
.circuitSectionImage::before {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.circuitSection1 {
  /* height: 20%; */
  display: flex;
  align-items: center;
  
  justify-content: space-between;
}

.circuitSection2 {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: nowrap;
}
.imageCircuit > img {
  object-fit: scale-down;
  height: 100%;
  width: 80%;
}
.circuitSection3 {
  width: 100%;
  bottom: 0;
}
.circuitSection3.backWhite {
  background-color: white;
  bottom: 0px;
  padding: 10px 0px;
}

.circuit-section__details {
  margin-bottom: 3vw;
}
.calendarWinRoundsPilots{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 40%;
  background: rgba(0, 0, 0, 0.2);
  border-width: 1px 0px;
  border-style: solid;
  border-color: #ffffff;
  backdrop-filter: blur(2px);
}

.calendarWinRoundsPilots .pilotsStanding{
  width: 80%;
}

@media screen and (max-width: 960px) {
  .circuitSection {
    height: fit-content;
    margin: 0 auto;
  }
  .circuitSection2 {
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .circuitSection3 {
    background-color: white;
    padding: 10px 0;
  }
  .circuitSectionImage {
    object-fit: scale-down;
  }
  .circuitSectionImage .uk-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .circuit-section__details {
    justify-content: center;
  }
  .imageCircuit {
    text-align: center;
  }
  .imageCircuit img {
    max-width: 350px;
  }
  .calendarWinRoundsPilots{
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .circuitSection1{
    flex-direction: column;
    gap: 10px;
  }
  .circuitSection {
    margin: 0 auto;
  }
  .circuitSection3 {
    position: relative;
    bottom: 0;
  }
  .imageCircuit {
    display: flex;
    align-items: flex-end;
    height: 60%;
    justify-content: center;
  }
  .imageCircuit > img {
    height: 90%;
  }
  .circuitSectionImage .uk-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}
