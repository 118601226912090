.posteDate {
  width: fit-content;
  padding: 20px;
  /*background-image: url("../../../assets/image/DateRectangle.png");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ed0033;
  margin-left: 20px;
  position: relative;
}

.posteDate::before {
  background-image: url("../../../assets/image/DateRectangle.png");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 20px;
}
.posteDate::after {
  background-image: url("../../../assets/image/DateRectangle.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 20px;
}

@media screen and (max-width: 600px) {
  .posteDate {
    margin-left: 0;
    width: 80%;
    white-space: nowrap;
  }
  .posteDate::before {
    display: none;
  }
}