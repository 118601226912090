.InfoCircuitRound{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.InfoCircuitRoundContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.flagLargeTrack{
    width: 2em ;
}

.InfoCircuitRound .round{
    font-size: 80px;
    color: #D9D9D9;
    font-family: transducer-extended, sans-serif;
    font-weight: 600;
    font-style: italic;
}
.InfoCircuitRound .info {
    display: flex;
    flex-direction: column;
}
.InfoCircuitRound .info .country{
    display: flex;
    gap: 5px;
    align-items: center;
}

.InfoCircuitRound .info .country .h4 {
    text-transform: capitalize;
}


@media screen and (max-width: 600px) {
    .InfoCircuitRound{
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
    .InfoCircuitRound .round{
        font-size: 50px;
    }
    .InfoCircuitRound .date{
        padding: 10px;
    }
    .InfoCircuitRound .info .country img {
        width: 40px;
        height: 27px;
    }
}
