html,body{
	margin: 0px;
}
#crmWebToEntityForm.zcwf_lblRight {
	width:90%;
	max-width: 540px;
	padding: 25px;
	margin: 0 auto;
	box-sizing: border-box;
}
#crmWebToEntityForm.zcwf_lblRight * {
	box-sizing: border-box;
}
#crmWebToEntityForm{text-align: left;}
#crmWebToEntityForm * {
	direction: ltr;
}
.zcwf_lblRight .zcwf_title {
	width: 80%;
	margin: 0 auto;
	font-size: 36px;
	line-height: 40px;
	word-wrap: break-word;
	padding: 0px 6px 10px;
	font-weight: bold;
}
.zcwf_lblRight .zcwf_text {
	width: 80%;
	margin: 0 auto;
	font-size: 16px;
	line-height: 28px;
	word-wrap: break-word;
	padding: 0px 6px 10
}
.zcwf_lblRight .zcwf_col_fld input[type=text], .zcwf_lblRight .zcwf_col_fld textarea {
	width: 100%;
	border: 1px solid #c0c6cc !important;
	resize: vertical;
	border-radius: 2px;
	float: left;
}
.zcwf_lblRight .zcwf_col_lab {
	word-break: break-word;
	padding: 0px 6px 0px;
	margin-right: 10px;
	margin-top: 5px;
	float: left;
	min-height: 1px;
	text-align: left;
	color: #0f0f0f;
}
.zcwf_lblRight .zcwf_col_fld {
	float: left;
	width: 100%;
	position: relative;
	margin-top: 5px;
}
.zcwf_lblRight .zcwf_privacy{padding: 6px;}
.zcwf_lblRight .wfrm_fld_dpNn{display: none;}
.dIB{display: inline-block;}
.zcwf_lblRight .zcwf_col_fld_slt {
	width: 60%;
	border: 1px solid #ccc;
	background: #fff;
	border-radius: 4px;
	font-size: 12px;
	float: left;
	resize: vertical;
	padding: 2px 5px;
}
.zcwf_lblRight .zcwf_row:after, .zcwf_lblRight .zcwf_col_fld:after {
	content: '';
	display: table;
	clear: both;
}
.zcwf_lblRight .zcwf_col_help {
	float: left;
	margin-left: 7px;
	font-size: 12px;
	max-width: 35%;
	word-break: break-word;
}
.zcwf_lblRight .zcwf_help_icon {
	cursor: pointer;
	width: 16px;
	height: 16px;
	display: inline-block;
	background: #fff;
	border: 1px solid #c0c6cc;
	color: #c1c1c1;
	text-align: center;
	font-size: 11px;
	line-height: 16px;
	font-weight: bold;
	border-radius: 50%;
}
.zcwf_lblRight .zcwf_row {margin: 15px 0px;}
.zcwf_lblRight .formsubmit {
	margin-right: 5px;
	cursor: pointer;
	color: var(--baseColor);
	font-size: 12px;
}
.buttonContainer{
	display: flex;
	justify-content: flex-start;
}
.zcwf_lblRight .zcwf_privacy_txt {
	width: 90%;
	color: rgb(0, 0, 0);
	font-size: 12px;
	font-family: Arial;
	display: inline-block;
	vertical-align: top;
	color: var(--baseColor);
	padding-top: 2px;
	margin-left: 6px;
}
.zcwf_lblRight .zcwf_button {
	font-weight: 800;
	color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  white-space: nowrap;
	padding: 10px 30px;
  min-width: 121px;
  height: 36px;
	background: #0f0f0f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0;
}
.zcwf_lblRight .zcwf_tooltip_over{
	position: relative;
}
.zcwf_lblRight .zcwf_tooltip_ctn{
	position: absolute;
	background: #dedede;
	padding: 3px 6px;
	top: 3px;
	border-radius: 4px;word-break: break-word;
	min-width: 100px;
	max-width: 150px;
	color: var(--baseColor);
	z-index: 100;
}
.zcwf_lblRight .zcwf_ckbox{
	float: left;
}
.zcwf_lblRight .zcwf_file{
	width: 55%;
	box-sizing: border-box;
	float: left;
}
.clearB:after{
	content:'';
	display: block;
	clear: both;
}
@media all and (max-width: 600px) {
	.zcwf_lblRight .zcwf_col_lab, .zcwf_lblRight .zcwf_col_fld {
		width: auto;
		float: none !important;
	}
	.zcwf_lblRight .zcwf_col_help {width: 40%;}
	.zcwf_lblRight.zcwf_rtl .zcwf_col_lab {text-align: left;}
	.zcwf_lblRight.zcwf_rtl .zcwf_col_lab {text-align: right;}
}

.col_flex {
	display: flex;
}

.zcwf_lblRight .zcwf_col_lab label {
	color: #fff;
}