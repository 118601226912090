.teamsContainer{
  width: 100%;
}
.contentTeams{
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .contentPilot{
    /* width: fit-content; */
    margin: auto;
  }
  .contentPilot{
    padding: 0 5px;
  }
}