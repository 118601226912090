.statsEuroCupContainer{
  display: flex;
  align-items: center;
  gap: 5px;
}
.backgroundStats{
  white-space: nowrap;
  background-color: #FAFAFA;
  padding: 2px 10px;
  flex-grow: 1;
}