.subFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0px;
    background-color: #0F0F0F;
    width: 100%;
    height: 44px;

    border-top: 1px solid rgba(250, 250, 250, 0.2);

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
}
.subFooter>div{
    padding: 0px 15px;
}

.subFooter .p{
    font-size: 14px;
    line-height: 14px;
}

@media screen and (max-width: 600px) {
    .subFooter .p{
        font-size: 10px;
        line-height: 10px;
        text-align: center;
    }
}