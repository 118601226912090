.containTeamHeader{
    margin: 50px;
    width: 100%;
    height: 54px;
    margin: 5vh auto;
    background: linear-gradient(98.87deg, #1C1E20 50.06%, #0F1112 67.81%, #3B4346 70.35%, #010101 100%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.contentTeamHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 50%;
    height: 100%;
}
.colorTeamHeader{
    width: 10px;
    height: 100%;
}
.iconTeamHeader{
    width: 100px;
    max-height: 100%;
    margin: auto 0;
}

.buttonTeamHeader{
    margin: auto 10px ;
}
.carTeamHeader{
    min-width: 100px;
    width:auto;
    max-width: 350px;
}
@media screen and (max-width: 960px){
    .containTeamHeader{
        width: 100%;
        min-width: 320px;
    }
  }
@media screen and (max-width: 600px){
    .carTeamHeader{
        display: none;
    }
  }