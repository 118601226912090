.icons--small {
    height: 18px;
    width: 18px;
  }
  .icons--medium {
    height: 26px;
    width: 26px;
  }
  .icons--large {
    height: 36px;
    width: 36px;
  }
  .icons--big {
    height: 48px;
    width: 48px;
  }
  .icons--huge {
    height: 64px;
    width: 64px;
  }
  .buttonIcon{
    cursor: pointer;
  }