.newsCard{
    display: flex;
    padding: 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}

.newsCard .p {
    font-size: 14px;
    line-height: 15px;
}
.default.newsCard{
    flex-direction: column;
    align-items: flex-start;
}
.latest.newsCard{
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.full.newsCard{
    flex-direction: column;
    position: relative;
    width: 100%;
}
.subTitlePost{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}
.full.newsCard:after {
    content: "";
    display: block;
    padding-bottom: 95%;
}

.full.newsCard > div {
    position: absolute;
    width: 100%;
    height: 100%;
}

.newsCard-contents {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}
.img-wrap {
    flex-grow: 1;
    position: relative;
}

.img-wrap img{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
}

.imageNews{
    object-fit: cover;
    z-index: 1;
}
.default .imageNews{
    max-height: 412px;
    width: 100%;
}
.full .imageNews{
    height: 100%;
    width: 100%;
}
.latest .imageNews{
    height: 100%;
    width: 60%;
}


.contentNewsCard{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}
.default .contentNewsCard{
    margin: 10px auto;
    gap: 10px;
    height: fit-content;
    width: 90%;
    z-index: 3;
}
.full .contentNewsCard{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    gap: 10px;
    z-index: 3;
    background: rgba(0,0,0, 0.5);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
}
.latest .contentNewsCard{
    justify-content: flex-start;
    padding: 20px;
    gap: 5vh;
    height: 100%;
    width: 40%;
    z-index: 3;
}
.latest  .newsCard-contents {
    flex-direction: row;
}

.latest  .newsCard-contents .img-wrap:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: -1px;
    z-index: 1;
    background: rgb(255,255,255);
    background: linear-gradient(100deg, rgba(255,255,255,0) 84.8%, rgba(255,255,255,0.5018382352941176) 85%, rgba(255,255,255,0.5) 89.8%, rgba(255,255,255,1) 90%);
}

.topContentNewsCard{
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topContentNewsCard .p-small{
    text-align: end;
}
.topContentCategories{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    max-width:70%;
    overflow: hidden;
}
.textNewsCard{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@media screen and (max-width: 960px) {
    .default.newsCard {
        min-height: 360px;
    }
    .newsCard-contents {
        flex-grow: 1;
    }
    .latest  .newsCard-contents .img-wrap:after {
        display: none;
    }
}