.listStandingContainer{
  /* min-width: 150px; */
  width: auto;
  position: relative;
  overflow-y: visible;
}
.listStandingHeader{
  background: #FFFFFF;
  /* height: 75px; */
  border-radius: 5px 5px 0 0;
  position: relative;
}
.listStandingHeader::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.listStandingHeaderInfo{
  display: flex;
flex-direction: column;
align-items: center;
padding: 8px 12px 8px 12px;
}
.listStandingNameRound{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
}
.listStandingCellHeader{
  display: flex;
  padding: 8px 0;
  background: #FFFFFF;
  border-top: 1px solid #f5f5f5;
}
.TeamCellHeader{
  display: flex;
  justify-content: space-around;
}
.listStandingCellHeader div{
  width: 25%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
.listStandingFR{
  color: #50708F;
  font-weight: 600;
}
.listStandingSR{
  color: #7E7E7E;
  font-weight: 600;
}

.bottomNav {
  height: 100px;
  position: relative;
}
.bottomNav:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.bottomNav .listStandingNameRound {
  background-color: #FFFFFF;
  text-align: center;
  justify-content: center;
  padding-bottom: 10px;
}