.NoStream{
  width: max-content;
  margin: auto;
  height: 80%;
  display: flex;
  align-items: center;
  font-size: 7em;
  font-weight: 500;
  color: #000000;
}
.NoStream img{
  width: 100%;
  height: 100%;
  object-fit:cover;
}