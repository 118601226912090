.pagination{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
}

.numPagination{
    cursor: pointer;
    margin: 0px 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 30px;
    height: 36px;
    /* Background/light */
    background: #FFFFFF;
    border-radius: 5px;
}

.numPagination.uk-active{
    background: #ED0033;
    color: #fff;
}