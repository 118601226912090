.marginTableStanding{
  width: 100%;
}
.marginTableStanding.uk-slider-container {
  /* overflow: visible !important; */
}

.imagenSlider{
  position: absolute;
  bottom: 40%;
  left: 0%;
  display: none;
}
.sliderPrincipal{
  width: 75%;
  overflow: hidden;
}

.sliderPrincipal .uk-panel{
  padding: 0 1px;
}
.sliderPrincipal .uk-panel .listStandingHeader,
.sliderPrincipal .uk-panel .listStandingCellHeader,
.sliderPrincipal .uk-panel .standingCell
{
  transition: all ease .3s;
}

.sliderPrincipal .uk-panel:hover .listStandingHeader,
.sliderPrincipal .uk-panel:hover .listStandingCellHeader,
.sliderPrincipal .uk-panel:hover .standingCell
{
  background-color: #f7f5f3;
}

.sliderPrincipal .uk-panel .standingCell {
  position: relative;
}

.sliderPrincipal .uk-panel .standingCell:after {
  position: absolute;
  top: 0;
  left: -100vw;
  right: -100vw;
  bottom: 0;
  content: '';
  background-color: rgba(255, 0, 0, 0);
  pointer-events: none;
  z-index: 10;
}
.sliderPrincipal .uk-panel .standingCell:hover:after {
  background-color: rgba(236, 236, 236, 0.329);
}

.sliderPrincipal .uk-position-relative .uk-slider-container .uk-position-top-left {
  height: 75px;
  padding: 10px;
}
.sliderPrincipal .uk-position-relative .uk-slider-container .uk-position-top-right {
  height: 75px;
  padding: 10px;
}

.sliderPrincipal .uk-position-relative .uk-slider-container a svg polygon {
  fill: gray;
}
.sliderPrincipal .uk-position-relative .uk-slider-container a:hover svg polygon {
  fill: red;
}


.standingTableAlign{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap:2px;
}
.sliderStandings{
  height: 100%;
}
.sliderStandings li{
  margin: 0 2px;
}

.labelPilotPoint{
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 15px;
  gap: 20px;
}


.standingTableAlign thead{
  height: 75px;
}
.standingTableAlign table {
  border-collapse: separate;
  border-spacing:  0px 2px;
  margin: 0 !important;
}

.standingTableAlign .uk-table th {
  padding-bottom: 10px;
}

.totalPilotsStanding {
  flex-shrink: 1;
  flex-basis: content;
}

@media screen and (max-width: 900px) {
  .sliderPrincipal{
    width: 65%;
  }

}
@media screen and (max-width: 650px) {

  .sliderPrincipal{
    width: 55%;
  }
  .totalPilotsStanding{
    width: 30%;
  }

  .standingTableAlign  table thead th:nth-child(2){
    display: none;
  }
  .standingTableAlign table tbody td:nth-child(2 ){
    display: none;
  }
}

.spacerCell {
  height: 98px;
}
.spacerCell td {
  padding: 0;
}