.filaTableStandings{
  background-color: #fff;
  height: 34px;
  border-top: 2px solid antiquewhite;
}
.filaTableStandings td{
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
  vertical-align: middle ;
}

.filaTableStandings .nameStandings div{
  white-space: nowrap;
  max-width: 8em;
  text-align: left;
}

.filaTableStandings .nameStandings{
  display: flex;
  gap: 5px;
  align-items: center;
}

 @media screen and (max-width: 960px) {
  .pointsStandingsTable{
    vertical-align:top !important;
  }
  .filaTableStandings .nameStandings{
    max-width: 6em;
  }
  .filaTableStandings .nameStandings div{
    white-space: wrap;
    overflow: visible; 
    text-overflow: ellipsis;

  }
 }
 @media screen and (max-width: 600px) {

  .filaTableStandings .nameStandings{
    max-width: 4em;
  }
 }