.horariosTable{
  width: 95%;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 30px;
  background-color: black;
  border-radius: 5px;
}

.horariosTable__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.horariosTable__item:last-child {
  border-bottom: none;
  padding-bottom: 20px;
}

.horariosTable__item .p{
  flex-grow: 1;
}

.horariosTable__item.headrow {
  background-color: #c4002a;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px 5px 0 0;
  text-align: center;
  font-family: transducer-extended, sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: 20px;
  justify-content: center;
}


.horarioCell{
  text-wrap: nowrap;
  /* background-color: #ed0033; */
  padding: 10px;
}

.schtime {
  text-align: center;
  width: 5em;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  line-height: 1em;
}
.schtime div {
  font-weight: normal;
  font-size: 16px;
}