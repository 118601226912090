.containPilotCard{
    position: relative;
    width: 100%;
    min-width: 170px;
    width: auto;
    max-width: 200px;
    height: fit-content;
    background-color: #FAFAFA;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.topPilotCard{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    z-index: 5;
}
.numPilotCard{
    padding: 5px 15px;
    background-color: #FAFAFA;
    border-radius: 0px 0px 10px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.flagPilotCard {
    position: absolute;
    top: 5px;
    right: 0;
}
.flagPilotCard > img{
    border-radius: 5px;
}
.imagePilotCard {
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}
.imagePilotCard:before {
    padding-bottom: 100%;
    content: '';
    display: block;
}

.imagePilotCard img{
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    max-width: fit-content;
    transform: translateX(-50%);
}

.namePilotCard{
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7px;
    gap: 5px;
    background-color: #FAFAFA;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
}
.namePilotCardText .h6 {
    text-align: center;
    line-height: 18px;
}
.teamsPilotCardText{

}