.tags{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: fit-content;
    height: fit-content;

    /* Main/Primary */

    background: #ED0033;
    border-radius: 10px;
}