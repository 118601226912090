.timerContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 30px 0 0;
    isolation: isolate;
    gap: 30px;
    /* width: 340px; */
    height: 80px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    position: relative;
}

.timerContainer .arrows {
    max-height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.timerContainer .arrows > img {
    height: 100%;
}

.timerContainer:after {
    content: '';
    display: none;
    width: auto;
    min-width: 40px;
    height: 100%;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    background-image: url("../../../assets/image/reporter-endcap.svg");
    background-position: left;
    background-repeat: no-repeat;
}

.timerNameTrack{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
}
.timerCount{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.timerCount>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 40px;
}

@media (min-width: 960px) {
    .timerContainer:after {
        display: block;
    }
    .timerContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 60px 0 0;
        width: auto;
        height: 57px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        box-shadow:0 0 0 transparent;
        border-radius: 0;
        overflow: visible;
        /* background: linear-gradient(110deg, rgba(255,255,255,1) 95.5%, rgba(0,212,255,0) 96%); */
    }
    .timerContainer>img{
        margin-left: 10%;
    }
    .timerContent{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        white-space: nowrap;
    }
    .timerContent .s2 {
        white-space: nowrap;
    }
}