.roundInfo {
  background: radial-gradient(ellipse at top,  #2c2a2b 0%,#090708 100%);
  min-height: 250px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.roundInfo .posteDate .heroText {
  font-size: 28px;
}

.alignRoundInfo{
  width: 90%;
  margin: 0 auto;
}

.roundInfoS2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.roundInfoS3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
