.firstBar{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    /* padding: 0px 5.1%; */
    background-color: black;
    width: 100%;
    gap: 15px;
    margin: 0 auto;
}

.userFirstBar{
    display: flex;
    justify-content: center;
    align-items: center;
}

.uk-navbar-container.uk-navbar-sticky .firstBar {
    display: none;
}
.LiveTrack{
    display: flex;
    padding-left: 200px;
    margin: auto;

}
.LiveTrack div{
    padding: 0px 10px;
    cursor: pointer;
    color: white;
}