.verticalNav{
    /* width: 100%; */
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 50;
    transform: translateY(-50%);
}
.verticalNav>li.uk-active{
    content: "";
    position: absolute;
    top: 15px;
    left: -25px;
    width: 15px;
    border-top: 1px solid #4C89F2;
}

.calendar-slider-mobile {
    margin: 0;
    padding: 0;
    background-color: black;
}
.calendar-slider-mobile .uk-grid {
    margin-left: 0;
}

.calendar-slider-mobile .uk-slider-items li {
    padding-left: 1px;
}

.calendar-slider-mobile .circuitSectionImage {
    padding-top: 0;
}

.calendar-slider-mobile .InfoCircuitRoundContainer {
    order: 2;
}

.calendar-slider-mobile .InfoCircuitRound {
    width: 100%;
    text-align: center;
    align-items: center;
}
.calendar-slider-mobile .posteDate {
    width: 100%;
}
.calendar-slider-mobile .trackDetail, .calendar-slider-mobile .circuit-section__details {
    margin-bottom: 0;
    border-bottom: none;
}
.calendar-slider-mobile .circuitSection {
    margin-bottom: none;
}
.calendar-slider-mobile .uk-dotnav>.uk-active>* {
    background-color: #fff;
}
.calendar-slider-mobile .uk-dotnav>*>* {
    border-color: #fff;
}

.calendar-page-section .circuitSectionImage {
    justify-content: center;
}

.calendar-page-section .circuitSectionImage .uk-container {
    padding-left: 100px;
    padding-right: 100px;
}

.calendar-page-section .imageCircuit {
    background: rgba(0, 0, 0, 0.2);
    border-width: 1px 0px;
    border-style: solid;
    border-color: #ffffff;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}

.calendar-page-section .imageCircuit {
    text-align: center;
}

.notice {
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .verticalNav{
        width: 100%;
        top: 46px;
    }
}

@media screen and (max-width: 960px) {
    .calendar-page-section .imageCircuit {
        width: 80%;
    }
}