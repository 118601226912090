.newsletterBlock{
    overflow: hidden;
}
.newsletterContent{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    padding: 10px 0px;
    margin: 10px 0px;
    border-top: 1px solid #ffffff44;
    gap: 15px;
}
