.aboutPostsTemplate{
    margin: 3rem auto;
}

.wp-block-columns {
    display: flex;
    gap: 20px;
    margin-bottom: 2rem;
}

.wp-block-column {
    flex-basis: 50%;
}

.has-text-align-center {
    text-align: center;
}
.aligncenter {
    margin: auto;
    text-align: center;
}

.aboutPostsTemplate:last-child {
    margin-bottom: 5rem;
}


/* nav about */
.navAboutPosts{
    margin: 4vh 0;
}
/* Special sections */
/* The car */
.aboutPostsTemplate .carstats .wp-block-column {
    border: solid 1px #dadada;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    background-color: #f3f3f3;
    box-shadow: 0px 10px 13px -7px #00000080, 5px 5px 15px 5px rgba(0,0,0,0);
}
.aboutPostsTemplate .carstats .wp-block-column:after {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 200px;
    width: 200px;
    background: linear-gradient(110deg, rgba(237,0,51,0.7) 8%, rgba(237,0,51,0) 9%);
    z-index: 1;
    border-radius: 10px;
    content: '';
}

/* The championship */
.aboutPostsTemplate .details-columns {
    text-align: center;
    margin-top: 6rem;
}
.aboutPostsTemplate .details-columns p {
    margin-top: 0;
    margin-bottom: 0;
}
.aboutPostsTemplate .details-columns h5 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.3em;
    font-family: transducer-extended, sans-serif;
    font-weight: 600;
    border-top: solid 1px #e5e5e5;
}

@media screen and (max-width: 1300px){

}
@media screen and (max-width: 960px){

}
@media screen and (max-width: 600px){
    .wp-block-columns {
        flex-direction: column;
    }
}