.teamTemplatesS1 {
  padding: 4vh 0;
  text-align: center;
  min-height: 40vh;
}
.driversTeamTemplate {
  height: auto;
  padding: 4vh 0;
  width: fit-content;
  margin: 0 auto;
}
.teamsTemplatesS11{
  width: 60%;
  margin: 0 auto;
  padding: 5vh 0;
}
.textHistory{
  overflow-wrap: anywhere;
}
.teamsTemplatesS2 {
  text-align: center;
}

.TeamsPageCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TeamsPageCenter .wp-block-image.size-full {
  border-radius: 10px;
}

.TeamsPageCenter .wp-block-social-links {
  display: flex;
  padding-left: 0;
  list-style: none;
  gap: 30px;
  margin-top: 0;
  margin-bottom: 40px;
}

.TeamsPageCenter .wp-block-social-links span {
  vertical-align: middle;
  padding-left: 6px;
}




@media screen and (max-width: 600px) {
  .listPilotCardTeam{
    width: fit-content;
    margin: auto;
  }
  .driversTeamTemplate .listPilotCardTeam {
    padding-left: 0;
  }
}