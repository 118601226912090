.containerCharacteristic{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nameCharacteristicValue{
    font-size: 1.5rem;
    font-weight: bold;
    color: #ed0033;
}