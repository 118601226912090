.filterSection{
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin: 2vh auto;
  /*<---  !SWITCH */
}
.selectFilter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    width: fit-content;
}
.selectFilter > label{
    white-space: pre; 
}