
.VerticalNavItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 91px;
    height: 3rem;
}
.uk-active .VerticalNavItem{
    padding: 10px 0;
}
.verticalNavItemRound{
    display: none;
}
@media screen and (max-width: 600px) {
    .VerticalNavItem{
        width: fit-content ;
        height: fit-content;
    }
}