.newsSection{
    height: fit-content;
    width: 100%;
}
.firstNewsCard{
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 100%;
}

.newsCard{
    height: 100%;
}

.firstNewsCardPage{
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    /* height: 100%; */
}
.newsSectionGrid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 0.5fr);
    grid-gap: 20px;
    height: 90%;
    margin:auto;
}
.newsPageGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows:1.5fr;
    grid-gap: 20px;
    width: 80%;
    height: 100%;
    margin:auto;
}
.sliderNews{
    width: 100%;
    margin: 3vh auto;
}

@media screen and (max-width: 960px) {
    .newsPageGrid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 340px);
        width: 80%;
        height: fit-content;
    }
    .newsSectionGrid{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 340px);
        width: 100%;
        height: fit-content;
    }
    .firstNewsCardPage{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        height: 100%;
    }
    .firstNewsCard{
        margin: auto;
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}
