.driverTemplatesS1{
  width: 60%;
  margin: 0 auto;
  padding: 5vh 0;
}

@media screen and (max-width: 960px){
  .driverTemplatesS1{
    width: 100%;
    margin: 0 auto;
    padding: 5vh 0;
  }
}