.secondBarConatiner{
    width: 100%;
    position: relative;
    top: 0px;
    height: auto;
    max-height: 60px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}
.secondBarContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 7%; */
    width: 90%;
    height: 80%;
    margin: auto;
}

.secondBarMenu{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    width: 30%;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.navbar-logo {
    cursor: pointer;
    max-width: 76px;
    max-height: 43px;
    margin: 10px 0;
}
.navbar-logo img {
    height: 43px !important;
    width: 76px !important;
}

@media screen and (max-width: 768px) {
    .navbar-logo img{
        height: 22px;
        width: 40px;
    }
}

.secondBarContent .uk-dropdown {
    padding: 15px;
}

.secondBarContent .seasons-dropdown {
    color: #ca022e;
    font-weight: 800;
    font-size: 1rem;
    font-family: transducer, sans-serif;
}
.secondBarContent .seasons-dropdown li {
    border-bottom: solid 1px #efefef;
}
.secondBarContent .seasons-dropdown li a:hover {
    color: black;
}
.secondBarContent .seasons-dropdown li:last-child {
    border-bottom: none;
}


.liOffCanvas>a{
    position: relative;
    justify-content: flex-start !important;
    margin: 0.3em 0px;
}

@media (max-width: 600px) {
    .secondBarConatiner{
        height: 45px;
    }
    .navbar-logo .LogoEuroCup {
        width: 40px;
    }
    .navbar-logo {
        padding: 0;
    }
}