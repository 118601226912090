.infoDriverContainer{
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
  background: linear-gradient(94.79deg, rgba(6, 5, 5, 0.2) 10.74%, rgba(255, 255, 255, 0) 11.1%), linear-gradient(180deg, rgba(0, 0, 0, 0.2) 76.56%, rgba(225, 211, 211, 0.132) 80.21%), linear-gradient(95.67deg, #000000 0.76%, rgba(0, 0, 0, 0) 55.2%), #2C3034;
}
.infoDriverTop{
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}
.infoDriverTopText{
  padding: 2vh 0;
}
.infoDriverTopTextName{
  display: flex;
  gap: 5px;
  align-items: center;
}

.infoDriverTopTextName img{
  object-fit: contain;
  width: 40px;
}
.infoDriverTopTextName .flag-wrap{
  display: flex;
  height: 30px;
  border-radius: 5px;
  overflow: hidden;
}

.infoDriverTopTextName .h1 {
  font-size: 36px;
}

/* .infoDriverContent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5vw;
  align-items: center;
} */

.width-80 {
  max-width: 80%;
}
.infoDriverImage{
  position: relative;
  width: 40%;
  padding-left: 0;
}
.driverNum{
  position: absolute;
  top: 0;
  left: 0;
  width: 50px !important;
  height: 40px;
  background: #ffffff;
  text-align: center;
  border-radius: 0px 0px 10px 0px;
  font-family: transducer-extended, sans-serif;
  font-weight: 600;
  font-style: italic;
  padding-top: 7px;
}
.infoDriverImage > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.infoDriverText{
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}
.infoDriverText1{

}

.standingPoints {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.bioDriver {
  margin-top: 10px;
}
.bioDriver .p-large {
  text-align: left !important;
}


@media screen and (max-width: 960px){

}


@media screen and (max-width: 600px){
  .infoDriverTop{
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .infoDriverContent{
    padding-top: 5vh;
    flex-direction: column;
    gap: 5vh;
  }
  .infoDriverImage{
    width: 100%;
  }
  .infoDriverText{
    width: 100%;
  }
  .infoDriverTopText{
    text-align: center;
  }
  .infoDriverTopTextName{
    flex-direction: column-reverse;
    align-items: center;
  }
  .infoDriverTopTextName > div{
    text-align: center;
  }
  .infoDriverTopTextName > img{
    height: 5vh;
  }
}