.imageGallery{
    width: 100%;
    height: 70vh;
    object-fit: contain;
}
.galleryNav{
    background-color: #606060 ;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}