.tableResults{
  width: 80%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing:  5px 15px;
}
.tableResults  table{
  border-collapse: separate !important;
  border-spacing:  0px 10px !important;
  text-align: center;
  margin-bottom: 0px !important;
}

.lineTable{
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    overflow: hidden;
}
.lineTable td{
    text-align: center;
    font-size: 1em;
    vertical-align: middle;
}

.tableRPilotName{
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.numPositionTable{
  height: 100%;
  min-width: 1.5em;
  text-align: right;
  color: black;
  font-weight: 500;
}
.tableRPilotNameText{
  text-align: left;
  padding-left: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.tableResults tr{
  width: auto;
  overflow: hidden;
}
.tableResults td{
  width: auto;
  height: 100%;
}

.cancelledRace {
  text-align: center;
  background-color: #fff;
  padding: 3rem 0;
  border-radius: 5px;
  font-weight: 600;
}



.tableResults table {
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  
}
.tableResults table td,
.tableResults table th {
  padding: 0.5rem 1rem;
}
.tableResults table thead th {
  padding: 3px;
  position: sticky;
  text-align: center;
  /*width: 15vh;*/
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}
.tableResults table tr {
  background: #fff;
  padding: 4px 5px;
  text-align: center;
}

.tableResults table tbody th {
  padding: 0 5px;
  font-weight: 100;
  font-style: italic;
  text-align: left;
  position: relative;
}
.tableResults table thead th:first-child{
  width: 25px;
}
.tableResults table thead th:nth-child(2){
  width: 20vh;
}
.tableResults table tbody td:first-child{
  text-align: center;

}
.tableResults table tbody td:nth-child(2 ){
  text-align: left;
  padding-right: 50px;
}


[role="region"][aria-labelledby][tabindex] {
  width: 100%;
  max-height: 98vh;
  overflow: auto;
}
[role="region"][aria-labelledby][tabindex]:focus {
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  outline: 0;
}
@media screen and (max-width: 900px) {
  .tableResults{
    width: 100%;
  }
  .tableResults  table thead th:first-child{
    position: sticky;
    left: 0;
    width: 20px;
    z-index: 2;
  }
  .tableResults  table thead th:nth-child(2){
    position: sticky;
    left: 40px;
    z-index: 2;
    padding-right: 30px;
  }
  .tableResults  table tbody td:first-child{
    text-align: left;
    position: sticky;
    width: 20px ;
    background-color: #fff;
    left: 0px;
    z-index: 2;
  }
  .tableResults table tbody td:nth-child(2 ){
    text-align: left;
    position: sticky;
    background-color: #fff;
    left: 40px;
    padding-right: 40px;
    z-index: 2;
  }

}
@media screen and (max-width: 600px) {
  .tableResults  table {
    display: block;
    width: 100%;
  }
  .tableResults  table thead th:first-child{
    position: sticky;
    left: 0;
    width: 15px;
    z-index: 2;
  }
  .tableResults  table thead th:nth-child(2){
    position: sticky;
    left: 25px;
    z-index: 2;
    padding-right: 30px;
  }
  .tableResults  table tbody td:first-child{
    text-align: left;
    position: sticky;
    left: 0px;
    width: 5px;
    z-index: 1;
  }
  .tableResults table tbody td:nth-child(2 ){
    position: sticky;
    left: 20px;
    padding-right: 20px;
    z-index: 2;
  }
  .numPositionTable{
    display: none;
  }
 
}
