.containerSelectTags{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.listOfTags{
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}