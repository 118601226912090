.heightSectionWinRound{
  min-height: 350px;
  height: 70vh;
  background: 
  linear-gradient(
    286.45deg,
    rgba(6, 5, 5, 0.2) 43.36%,
    rgba(255, 255, 255, 0) 43.62%
  ),
  linear-gradient(
    107.79deg,
    rgba(76, 75, 75, 0.2) 10.74%,
    rgba(255, 255, 255, 0) 11.1%
  ),
  linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 76.56%,
    rgba(225, 211, 211, 0.132) 80.21%
  ),
  linear-gradient(95.67deg, #000000 0.76%, rgba(0, 0, 0, 0) 55.2%), #2c3034;
}
.h_100{
  height: 100%;
}

.winning-card {
  position: relative;
}

.winning-card .heroSmall{
  font-size: 32px;
}

.winning-card.uk-active:before {
  opacity: 0;
  transition: all ease-in 0.8s;
}

.winning-card:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background-color: #E10600;
  z-index: 1;
  opacity: 1;
  transition: all ease-in 0.1s;
  pointer-events: none;
}

.winning-card:after {
  position: absolute;
  top: -5vw;
  left: auto;
  right: -10vw;
  bottom: -5vw;
  content: '';
  background-color: #E10600;
  width: 20vw;
  transform: rotate(12deg);
  /* background: -moz-linear-gradient(100deg,  rgba(225,6,0,0) 0%, rgba(225,6,0,0) 50%, rgba(225,6,0,1) 51%, rgba(225,6,0,1) 100%);
  background: -webkit-linear-gradient(100deg,  rgba(225,6,0,0) 0%,rgba(225,6,0,0) 50%,rgba(225,6,0,1) 51%,rgba(225,6,0,1) 100%);
  background: linear-gradient(100deg,  rgba(225,6,0,0) 0%,rgba(225,6,0,0) 50%,rgba(225,6,0,1) 51%,rgba(225,6,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e10600', endColorstr='#e10600',GradientType=1 ); */
}
.centerWinPilot{
  margin-top: 0;
  height: 100%;
}

.heightSectionWinRound ul .winning-card:nth-child(2):after{
  left: 0;
  right: auto;
  display: none;
  /* background: -moz-linear-gradient(-74deg,  rgba(225,6,0,0) 0%, rgba(225,6,0,0) 50%, rgba(225,6,0,1) 51%, rgba(225,6,0,1) 100%);
  background: -webkit-linear-gradient(-74deg,  rgba(225,6,0,0) 0%,rgba(225,6,0,0) 50%,rgba(225,6,0,1) 51%,rgba(225,6,0,1) 100%);
  background: linear-gradient(-74deg,  rgba(225,6,0,0) 0%,rgba(225,6,0,0) 50%,rgba(225,6,0,1) 51%,rgba(225,6,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e10600', endColorstr='#e10600',GradientType=1 ); */
}

.trackWinner {
  text-transform: uppercase;
  text-align: left;
}

.nav-arrows {
  transform: scale(3.5);
}

.nav-arrows polyline{
  stroke: #fff;
  stroke-width: 2;
}

@media screen and (max-width: 600px) {
  .heightSectionWinRound{
    height: fit-content;
  }
  .winning-card:after{
    display: none;
  }
  .winning-card:before{
    display: none;
  }
}

@media screen and (max-height: 600px) {
  .centerWinPilot{
    margin-top: 0%;
  }
}