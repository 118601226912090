.transducer-variable {
}

.heroLarge {
  font-family: transducer-extended, sans-serif;
  font-style: italic;
  font-weight: 900;
  font-size: 64px;
  margin: 0;
  line-height: 80%;
}
.heroText {
  font-family: transducer-extended, sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: 42px;
  margin: 0;
  line-height: 80%;
}
.heroSmall {
  font-family: transducer-extended, sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: 25px;
  margin: 0;
  line-height: 80%;
}

h1,
.h1 {
  font-family: transducer;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  margin: 0;
  line-height: 48px;
}
.h2 {
  font-family: transducer;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  margin: 0;
  line-height: 40px;
  letter-spacing: 2px;
}
.h3 {
  font-family: transducer;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  line-height: 40px;
}
.h4 {
  font-family: transducer;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  margin: 0;
  line-height: 32px;
}
.h5 {
  font-family: transducer;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  line-height: 24px;
}
.h6 {
  font-family: transducer;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  line-height: 24px;
}
.s1 {
  font-family: transducer;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  line-height: 24px;
}
.s2 {
  font-family: transducer, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  line-height: 15px;
}
.p-large {
  font-family: transducer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}
.p {
  font-family: transducer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  line-height: 28px;
}
.p-small {
  font-family: transducer;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  margin: 0;
  line-height: 12px;
  letter-spacing: 0.06em;
}
.c1 {
  font-family: transducer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  line-height: 18px;
  /* identical to box height, or 112% */
  letter-spacing: -0.5px;
}
.c2 {
  font-family: transducer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  line-height: 16px;
}
.label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  line-height: 12px;
}
.b-giant {
  font-family: transducer-extended, sans-serif;
  font-style: normal;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  line-height: 24px;
}
.b-large {
  font-family: transducer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  line-height: 20px;
}
.b-medium {
  font-family: transducer;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  margin: 0;
  line-height: 16px;
}
.b-small {
  font-family: transducer;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin: 0;
  line-height: 16px;
}
.b-tiny {
  font-family: transducer;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  margin: 0;
  line-height: 12px;
}
.trackName {
  font-family: transducer;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  margin: 0;
  line-height: 18px;
  /* identical to box height, or 100% */
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.trackName-small {
  font-family: transducer, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 14px;
  margin: 0;
  line-height: 18px;
  /* identical to box height, or 129% */
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
}

/* Modifiers */
.caps {
  text-transform: uppercase;
}

/* COLORS */
.white {
  color: #ffffff !important;
}
.black {
  color: #000000 !important;
}
.red {
  color: #ed0033 !important;
}
.gray {
  color: #7e7e7e !important;
}

@media screen and (max-width: 960px) {
  .heroText {
    font-size: 4vw;
  }
  .heroSmall {
    font-size: 3vw;
  }
}
@media screen and (max-width: 600px) {
  .h4 {
    font-size: 20px;
  }
  .c1 {
    font-size: 12px;
    letter-spacing: 0;
  }
  .heroLarge {
    font-size: 14vw;
  }
  .heroText {
    font-size: 20px;
  }
  .heroSmall {
    font-size: 6vw;
  }
  .timerContent {
    text-align: center;
  }
  .b-medium {
    font-size: 2vw;
  }
  .trackName-small {
    font-size: 0.8rem;
  }
  .timerContent .s2.black {
    opacity: 0.5;
  }
}