.blogCard{
  display: flex;

  text-align: center;
  overflow: hidden;
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
}


.blogCard-contents {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.img-wrapBlog {
  width: 100%;
  min-height: 100px;
  height: auto;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
}


.imageBlogCard{
  object-fit: cover;
  z-index: 1;
  height: auto;
  max-height: 412px;
}


.contentBlogCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 10px auto;
  gap: 10px;
  height: fit-content;
  width: 90%;
  z-index: 3;
}

.topContentCategoriesBlogCard{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  max-width:70%;
  overflow: hidden;
}
.titleBlogCard{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.contentBlogCard{
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/* @media screen and (max-width: 960px) {
  .default.newsCard {
      min-height: 360px;
  }
  .newsCard-contents {
      flex-grow: 1;
  }
  .latest  .newsCard-contents .img-wrap:after {
      display: none;
  }
} */