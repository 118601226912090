.blockListContent{
    margin: auto;
}
.contentListContent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 10px 0px;
    margin: 10px 0px;
    border-top: 1px solid #ffffff44;
    gap: 10px;
}
.itemContentList{
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .blockListContent{
        margin-bottom: 30px;
    }
}