.faqCard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    height: 300px;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    /* Card Shadow */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.titleFaqCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0px;

    
    width:100%;
    height: 10%;
    border-radius:0 0 8px 8px;
    background: #FFFFFF;
}
.titleFaqCard > div{
    text-align: left;
    width: 90%;
    margin: auto;
}
.imageFaqCard{
    width:100%;
    height: 70%;
    position: relative;
}
.imageFaqCard img{
    position: absolute;
    left: 50%;
    top: 50%;
    --uk-position-translate-x: -50%;
    --uk-position-translate-y: -50%;
    transform: translate(var(--uk-position-translate-x),var(--uk-position-translate-y));
}