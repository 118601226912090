.registerSection{
    background-image: url("../../../assets/image/backgroundRegister.png");
    background-size: cover;
    height: 50vh;
    min-height: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}