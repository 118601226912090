.uk-nav-default>li.uk-active{
    background-image: url("../../../../assets/image/arrowNav.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.uk-nav-default>li.uk-active .verticalNavItemRound{
    display: block;
}
.horizontalNav{
    background-color: rgba(0, 0, 0, 0.719);
    z-index: 50;
    width: 100%;
    padding: 10px 0;
}
.horizontalNav>ul{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.horizontalNav li{
    height: fit-content;
}
.horizontalNav div{
    font-size: 6vh;
}
.horizontalNav .uk-active div{
    color:red !important;
}

@media screen and (max-width: 600px) {
    .uk-nav-default>li.uk-active{
        background-image: url();
        color:red;
    }
    .horizontalNav{
        display: flex;
        
    }
}