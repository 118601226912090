.contentFooter{
    width: 100%;
    background: #2c2a2b;
    background: -moz-radial-gradient(top, ellipse cover,  #2c2a2b 0%, #090708 100%);
    background: -webkit-radial-gradient(top, ellipse cover,  #2c2a2b 0%,#090708 100%);
    background: radial-gradient(ellipse at top,  #2c2a2b 0%,#090708 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2a2b', endColorstr='#090708',GradientType=1 );
    background: #2c2a2b;
    background: -moz-radial-gradient(top, ellipse cover,  #2c2a2b 0%, #090708 100%);
    background: -webkit-radial-gradient(top, ellipse cover,  #2c2a2b 0%,#090708 100%);
    background: radial-gradient(ellipse at top,  #2c2a2b 0%,#090708 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2a2b', endColorstr='#090708',GradientType=1 );
}
.alignContentFooter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 60px 0%; */
    gap: 44px;
    width: 80%;
    margin: auto
}
.contentFooterIcons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    width: 1152px;
    height: 32.77px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.contentFooterText{
    width: 100%;
    height: 159px;
    /* Inside auto layout */
    display: flex;
    gap: 175px;

    flex: none;
    order: 1;
    flex-grow: 0;
}
.separatorFooter{
    position: relative;
    top: 0px;
}
.textContFooter{
    width: 100%;

}

.logosContFooter {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-height: 40px;
}
.logosContFooter  .LogoEuroCup {
    margin: 0 auto;
    max-width: 300px;
    padding-left: 0;
}

@media screen and (max-width: 1000px){
    .logosContFooter {
        align-items: center;
        flex-direction: column;
        gap: 15px;
        max-height: 200px;
    }
    .logosContFooter .logoCentred {
        display: flex;
        align-items: center;
    }
    .contentFooterIcons{
        flex-direction: column;
    }
    .contentFooterText{
        flex-direction: column;
        gap:10px;
        justify-content: center;
        align-items: flex-start;
        margin: 0px 100px;
    }
}

@media screen and (max-width: 600px){
    .logosContFooter .LogoEuroCup {
        max-width: 80%;
        margin: auto;
    }
    .contentFooter {
        padding-bottom: 30px;
    }
}
    
