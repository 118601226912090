.teamInfo {
  width: 100%;
  height: 50vh;
  min-height: 250px;
}
.teamContent {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  background: linear-gradient(
      273.66deg,
      rgba(173, 21, 25, 0.2) 6.05%,
      rgba(255, 255, 255, 0) 6.31%
    ),
    linear-gradient(
      274.45deg,
      rgba(6, 5, 5, 0.2) 43.36%,
      rgba(255, 255, 255, 0) 43.62%
    ),
    linear-gradient(
      94.79deg,
      rgba(6, 5, 5, 0.2) 10.74%,
      rgba(255, 255, 255, 0) 11.1%
    ),
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 76.56%,
      rgba(225, 211, 211, 0.132) 80.21%
    ),
    linear-gradient(95.67deg, #000000 0.76%, rgba(0, 0, 0, 0) 55.2%), #2c3034;
}
.teamContentAlign{
  height: 100%;
  width: 80%;
  margin: 0 auto;
}
.teamDetails {
  width: 100%;
  min-height: 40px;
  max-height: 70px;
  height: 15%;
  background: #0f0f0f;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.teamDetails div {
  max-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.teamContent1 {
  padding-top: 2%;
  height: 20%;
}

.teamContentTitle {
  border-left: 10px solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
  border-radius: 5px 0px 0px 0px;
}
.teamContent2 {
  height: 50%;
  max-height: 100%;
  overflow: hidden;
}
.teamContent2 img{
  top: 20%;
}
.teamContentImages {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.teamContentImages .logoTeam {
  /* min-width: 50px;
  width: 20vh;
  height: 100%;
  object-fit: c; */
}

.carTeam{
  height: 100%;
}
.trackInfoTeamContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.trackInfoTeamChar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5%;
}

.teamInfo .socialDiv{
  justify-content: flex-end;
  max-width: none;
}

@media screen and (max-width: 960px) {
  .trackInfoTeamChar {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .teamInfo{
    height: fit-content;
  }
  .teamContentAlign{
    width: 100%;
  }
  .teamInfo .socialDiv{
    justify-content: center;
    
  }
  .teamContentTitle{
    border-left:0 ;
    border-bottom: 10px solid;
    align-items: center;
    padding: 0;
  }
  .teamContent1{
    flex-direction: column-reverse;
    align-items: center;
    gap: 5vh;
    padding: 10px 0;
  }
  .teamContent2{
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
    padding: 10px 0;
  }
  
}
