.standingCell{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2px auto;
  width: 100%;

  height: 34px;
  background: #FFFFFF;
}
.standingCellTeam{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 2px auto;
  width: 100%;

  height: 34px;
  background: #FFFFFF;
}

.standingCell div {
  width: 25%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: default;
}
.standingN1{
  color: #438ed7;
}
.standingN2{
  color: #7E7E7E;
}
.standing0{
  color: #E9E1D8 !important;
}
.standingCell .red {
  font-style: italic;
  letter-spacing: -0.1em;
  margin-left: -0.1em;
}