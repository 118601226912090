.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.kayakContainer {
  background-color: #fff;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 250px;
}

.kayakContainer iframe {
  position: absolute;
  top: 30px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (max-width: 1300px) {
  .kayakContainer {
    padding-top: 350px;
  }
}
@media screen and (max-width: 1000px) {
  .kayakContainer {
    padding-top: 450px;
  }
}

@media screen and (max-width: 600px) {
  .kayakContainer {
    padding-top: 500px;
  }
}
