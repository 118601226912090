.parnerIconsBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 0px 50px;
    gap: 30px;
    width: 100%;
    height: 170px;
    overflow: hidden;
    background: linear-gradient(180deg, #606060 0%, #606060 14.58%, #212121 100%);

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.partnerIcons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
    width: 100%;
    height: 100%;
}