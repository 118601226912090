.winPilotCardContainer{
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin: auto;
  flex-wrap: nowrap;
}
.winPilotCardContainer > div {
  width: 50%;
}
.winPilotCardContainer.reverse{
  flex-direction: row-reverse;
}

.winPilotCardContainer.reverse .carWinner img{
  transform: translateX(-30%);
}
.winPilotCardContainer .uk-height-medium {
  height: 100%;
  border-radius: 5px;
  width: 100%;
  overflow: visible;
}

.infoPilotWin {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 20px;
}
.trackWinner{

}
.nameWinner{
  text-align: left;
}
.nameWinner .s1{
  margin-left: 42px;
}

.namePilotWinner .h4 {
  white-space: nowrap;
  z-index: 4;
}
.namePilotWinner{
  display: flex;
  align-items: center;
  gap: 10px;
  text-shadow: 2px 2px 3px #2e2e2e;
}
.timeWinner{
  margin-left: 42px;
}
.carWinner {
  z-index: 5;
  position: relative;
  
}
.carWinner img{
  width: 130%;
  max-width: 130%;
}
.imageWinPilot{
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;

}
@media screen and (max-width: 900px) {
  .winPilotCardContainer{
    justify-content: space-around;
  }
}


@media screen and (max-width: 600px) {
  .winPilotCardContainer{
    height: fit-content;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .imageWinPilot {
    justify-content: center;
  }
  .winPilotCardContainer .uk-height-medium {
    min-height: 30vh;
    max-width: 50%;
  }
  .winPilotCardContainer.reverse{
    flex-direction: column-reverse;
  }
  .winPilotCardContainer > div {
    width: 90%;
    min-height: 30vh;
  }
  .infoPilotWin{
    width: 80%;
    align-items: center;
  }
  .carWinner{
    display: flex;
    height: 200px;
    width: 80%;
    position: absolute;
    top: 12vh;
    z-index: 0;
  }
  .carWinner img {
    height: fit-content;
    transform: none !important;
    max-width: 100% !important;
  }

}