.trackDetail {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 10px;
  min-width: 230px;
  background: rgba(0, 0, 0, 0.2);
  border-width: 1px 0px;
  border-style: solid;
  border-color: #ffffff;
  backdrop-filter: blur(2px);
}

.trackDetail > div {
  width: 100%;
}
.trackDetailContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 16px;
  margin: 10px auto;
  width: 90%;
}
.trackDetailContentItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: end;
  padding-bottom: 10px;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.lastItemTrackDetail {
  border-bottom: none;
}

@media screen and (max-width: 960px) {
  .trackDetail {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .trackDetail {
    width: auto;
    background-color: black;
    gap: 5vh;
    margin: 0 auto 30px;
  }
  .trackDetailContent {
    padding: 0px;
    gap: 10px;
    width: 100%;
  }
  .trackDetailContentItem {
    padding-bottom: 5px;
    gap: 4px;
  }
}
