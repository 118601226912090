.socialDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 428px;
    align-items: center;
}


