.circuitCard{
    position: relative;
    width: 100%;
    max-width: 250px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    overflow: hidden;
    border-radius: 5%;
    margin: auto;
    cursor: pointer;
}
.circuitCard:after{
    content: "";
    display: block;
    padding-bottom: 89%;
}
.circuitCardRound{
    background-image: url("../../../../assets/image/RectangleCard.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 700;

}
.circuitCardRound span{
    position: absolute;
    top: 5%;
    left: 5%;
    font-family: transducer-extended, sans-serif;
    font-weight: 600;
    font-style: italic;
}
.circuitCardContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}
.circuitCardImage {
    width: 100%;
    height: 70%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out .3s;
    position: relative;
}
.circuitCardImage:before {
    position: absolute;
    z-index: 1;
    background-color: #00000088;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    transition: all ease .2s;
}

.circuitCardImage img{
    width: 70%;
    z-index: 4;
}
.circuitCardContent{
    width: 100%;
    height: 30%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    gap: 2px;
    transition: all ease-in-out .3s;
}
.circuitCardName{
    display: flex;
    gap:10px;
}


.circuitCard:hover .circuitCardContent{
    background-color: #ED0033;
    height: 100%;

}
.circuitCard:hover .circuitCardImage img {
    opacity: 0;
}
.circuitCard:hover .circuitCardImage:before {
    background-color: #ED0033;
}

.circuitCard:hover .circuitCardContent div{
    color:#FFFFFF;
    font-weight: 900;
}

.circuitCard:hover .circuitCardName img {
    opacity: 0;
    width: 0;
}

.circuitCard:hover .circuitCardImage {
    height: 0%;
}


@media screen and (max-width: 600px){
    .circuitCardRound{
        font-size: 10px;
    }
    .circuitCardName img{
        position: absolute;
        bottom: 8%;
        left: 5%;
    }
    .circuitCardContent{
        height: 40%;
        justify-content: flex-end;
        gap:0;
    }
}