.aboutSection1{
    width: 70%;
    margin: 5% auto;
    text-align: center;
}
.aboutSection1 > div{
    margin: 3% 0;
}
.aboutSection2{
    width: 50%;
    margin: 5% auto;
}
.aboutSection2 > div{
    text-align: center;
}


@media screen and (max-width: 1300px){
    .aboutSection1{
        width: 80%;
    }
    
}
@media screen and (max-width: 960px){
    .aboutSection1{
        width: 70%;
    }
    .aboutSection2{
        width: 70%;
    }
}
@media screen and (max-width: 600px){
    .aboutSection2{
        width: 100%;
    }
}