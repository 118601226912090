.contentNewsTemplate{
    width: 100%;
    height: 100%;
    background-color: #FAFAFA;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
}
.contentNewsTitle{
    padding-bottom: 5vh;
}