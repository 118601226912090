.pilotsStanding{
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5vh;
  min-height: 50px;
}
.namePilotsStanding{
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px ;
}