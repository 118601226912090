.navItemWrap {
    display: block;
    position: relative;
}

.navItem{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    margin: auto;
    gap: 10px;
    height: 90%;
    max-width: 100px;
    border:0;
    cursor: pointer;
    border-bottom: 2px solid #ffffff00;
    transition: all ease .2s;
}

.navItem .b-tiny, .navItem .b-medium {
    white-space: nowrap;
}

.seoItem {
    position: absolute;
    z-index: -1;
    font-size: 8px;
    white-space: nowrap;
}


.navItem:active{
    flex-direction: column;
    padding: 2px 10px;
    background: rgba(229, 229, 229, 0.2);
    /* Background/Black */
    border:0;
    color: #0F0F0F;
    border-bottom: 2px solid #0F0F0F;
}
.navItem:hover{
    flex-direction: column;
    background: rgba(229, 229, 229, 0.2);
    /* Background/Black */
    border:0;
    color: #0F0F0F;
    border-bottom: 2px solid #0F0F0F;
}

.selectorNavItem{
    height: 5px;
    width: 11px;
    position: absolute;
    bottom: 0px;
}