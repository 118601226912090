.roundNav{
  cursor: pointer;
  width: 100%;
  background-color: black;
  height: 30vh;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.roundNavCard{
  width: 100%;
  display: flex;
  height: 100%;
}

.nextRound{
  height: 100%;
  width: 20%;
}

.nameNavRound {
  display: flex;
  align-items: center;
}
.nameNavRound div{
  color:white;
  font-size: 1.5em;
  margin: 5px;
  text-transform: uppercase;
  font-family: transducer-extended, sans-serif;
  font-weight: 600;
  font-style: italic;
}
.nameNavRound span{
  color: white;
  font-size: 2.5em;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .roundNav{
    height: 100%;
  }
  .nextRound{
    width: 20%;
  }
  .nameNavRound{
    justify-content: space-around;
  }
}
