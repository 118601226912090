.standingTempContainer{
  background-color: #EFEFEF;
  width: 100%;
  height: 100%;
  padding: 5vh 0;
}

.standingTempAlign{
  /* min-height: 100vh; */
  width: 80%;
  /* padding: 5vh auto; */
  margin: 0 auto 4rem;
}
.standingTempTitle{
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
.standingTempTitle .uk-flex {
  gap: 20px;
}

.standingTempTitle select {
    padding: 0 1.8rem 0 1.5rem;
    border-radius: 3px;
    border: none;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    cursor: pointer;
    height: 36px;
    margin-top: 6px;
    font-weight: 900;
    font-size: 16px;
    cursor: pointer;
}
.standingTempTitle select:hover {
  background-color: #cf002d;
  color: #fff;
}
.standingTempTitle select option {
  font-weight: 900;
}

@media screen and (max-width: 900px) {
  .standingTempAlign{
    width: 100%;
    margin: 0;
  }
}