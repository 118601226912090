.categoryTag {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 3px 10px;
    gap: 10px;
    background-color: #ED0033;
    max-width: max-content;
    cursor: pointer;
    white-space: nowrap;
    transition: all ease .3s;
}

.categoryTag:hover {
    background-color: #000000;
}