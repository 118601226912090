.newFotoPrincipal{
    width: 100%;
    object-fit: cover;
    height: 60vh;
}
.categoryDate{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem auto;
}
.categories{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
}

.contentNew {
    margin-bottom: 80px;
}
.contentNewImage{
    position: relative;
    width: 100%;
    display: inline-block;
}
.titleNew{
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    padding-bottom: 50px ;
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(0,0,0,0.5048144257703081) 100%);
}
.titleNew h1{
    max-width: 700px;
    margin: 0 auto;
}
.socialShare{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.socialShareIcons{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.principalContentNew{
    padding-bottom: 5rem;
    margin: 0 auto;
}

@media screen and (max-width: 600px){
    .socialShare{
        background-color: white;
        margin: auto;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
    }
    .socialShareIcons{
        flex-direction: row;
        margin: auto;
    }
}