.homeTemplateSection1{
    background-image: url("../../../assets/image/section-hero-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
    width: 100%;
    height: 85vh;
    position: relative;
}
.homeTemplateSection1Mask{
    /* background-image: url("../../../assets/image/hero-mask-general.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top; */
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap : 30px;
}
.homeTemplateSection1Mask div {
    z-index: 3;
}
.homeTemplateSection1Mask:after {
    background-image: url("../../../assets/image/hero-mask-general.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    content: '';
    height: 123.6vh;
    width: 300%;
    pointer-events: none;
}
.homeTemplateSection1Content{
    margin: 10vh 10% 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    max-width: 300px;
}
.hiddenTitle{
    position: absolute;
    top: -9999px;
}
.homeTemplateSection2{
    min-height: 60vh;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap : 45px;
    padding: 6rem 0;
    background: #E5E5E5;
    position: relative;
}
.homeTemplateSection2News {
    width: 80%;
    height: 80%;
}
.homeTemplateSection2News:after {
    position: absolute;
    content: '';
    height: 40%;
    width: 100%;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: url("../../../assets/image/home-lines.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
}
.homeTemplateSection3{
    background: #E5E5E5;
    text-align: center;
}
.homeTemplateSection3 .h2{
    margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
    .homeTemplateSection1Mask:after {
        display: none;
    }
    .homeTemplateSection1{
        height: 55vh;
        background-size: fill;
        background-position: center right;
    }

    .homeTemplateSection1Mask{
        background-image: none;
        justify-content: flex-start;
        align-items: center;
    }
    .homeTemplateSection2{
        min-height: 550px;
    }
    .homeTemplateSection2News:after {
        display: none;
    }
    .homeTemplateSection1{
        background-position: center center;
        background-image: url("../../../assets/image/section-hero-mobile-bg.jpg");
    }
    .homeTemplateSection2News .newsCard {
        width: 88%;
        margin: 0 6%;
    }
}
@media screen and (max-width: 960px){
    .homeTemplateSection1{
        background-position: center;
    }
    .homeTemplateSection2{
        height: fit-content;
    }
    .homeTemplateSection1{
        background-position: center center;
        background-image: url("../../../assets/image/section-hero-mobile-bg.jpg");
    }
}
.iframe-home iframe{
    width: 100%;
    height: 100vh;
    border: none;
}