.titleTeamsTemplate{
  display: flex;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 80%;
  border-bottom: 1px solid #272727; ;
}

.team-item-wrap {
  position: relative;
}

.skeleton-pilot {
  background-color: #f5f5f5;
  border-radius: 20px;
  /* width: 200px;
  height: 253px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707070;
}

.loadingNotice {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
}

.teamsTemplate{
  margin: auto;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 3vh;
  width: 80%;
}
.teamsTemplate > div {
  width: 100%;
}
.otherFormula {
  width: 80%;
  margin: auto;
  margin-bottom: 30px;
  text-align: center;
}

.otherFormula .h2 {
  margin-bottom: 10px;
  margin-top: 30px;
  opacity: .5;
  padding-bottom: 10px;
  border-bottom: solid 1px #eee;
  text-transform: uppercase;
  letter-spacing: 0;
}

.otherFormula img {
  cursor: pointer;
  display: block;
  transition: all ease .2s;
}
.otherFormula img:hover {
  opacity: .7;
}


@media screen and (max-width: 600px) {
  .titleTeamsTemplate{
    text-align: center;
    flex-direction: column;
    width: 100%;
  }
  .teamsTemplate{
    width: 100%;
  }
}