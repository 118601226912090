.blockInputText{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.blockInputText.inLine{
    background: #FFFFFF;
    flex-direction: row;
    border: 1px solid #E5E5E5;
    height: 40px;
    border-radius: 5px;
}
.blockInputText.inColumn{
    gap: 10px;
    flex-direction: column;
    height: object-fit;
    margin: 0 auto;
}

.inputText{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 5px;
    border:0;
}