.sideNavActive{
    display: inline-block;
    cursor: pointer;
}
.sideNavActive>div{
    color: rgb(88, 88, 88);
}
.sideNavActive>div:hover{
    color: rgb(0, 0, 0);
}
.sideNavActive>div:active{
    color: rgb(0, 0, 0);
}

.sideNavActive>div:before{
    content:">";
    opacity: 0;
    margin-right: 10px;
}

.sideNavActive>div:hover::before{
    opacity: 1;
}
.contentSideNavMenu{
    margin: 10px auto 0 -17px;
    display:flex;
    flex-direction: column;
    gap: 10px;
}
.sideNavMenu .s1 {
    border-bottom: solid 1px #E5E5E5;
    width: fit-content;
    padding: 0 1rem 5px 0;
}