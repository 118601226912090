.infoRoundsDetails {
  /* width: auto; */
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
}
.infoRoundsDetailsText {
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  margin-bottom: 10px;
  /* margin-left: 1vw; */
}
.infoRoundsDetailsRound {
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.iconsInfoRound{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}