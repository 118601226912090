
.selectContain {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding-right: 20px;
    padding-left: 10px;
    overflow: hidden;
}

.selector{
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    gap: 10px;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border: none;
}
